import React from 'react';
import {
  Create,
  TextInput,
  Show,
  Edit,
  EditButton,
  SimpleShowLayout,
  SimpleForm,
  List,
  Datagrid,
  TextField,
} from 'react-admin';

export const RunnerList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="pin" label="TYB Pin" />
      <EditButton />
    </Datagrid>
  </List>
);

export const RunnerCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="pin" />
    </SimpleForm>
  </Create>
);

export const RunnerShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="pin" />
    </SimpleShowLayout>
  </Show>
);

export const RunnerEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="pin" />
    </SimpleForm>
  </Edit>
);
