import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { unregister } from './registerServiceWorker';

// Error logging. Should be used in production. Can be use in staging.
const { REACT_APP_SENTRY_DSN: dsn } = process.env;
if (dsn) Sentry.init({ dsn });

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
