import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Responsive, userLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const LogoutButton = ({ userLogout: userLogoutFnc }) => (
  <Responsive
    xsmall={(
      <MenuItem onClick={userLogoutFnc}>
        <ExitIcon />
        {' '}
        Logout
      </MenuItem>
    )}
    medium={(
      <Button
        onClick={() => console.log(userLogoutFnc)}
        size="small"
      >
        <ExitIcon />
        {' '}
        Logout
      </Button>
    )}
  />
);

LogoutButton.propTypes = {
  userLogout: PropTypes.func.isRequired,
};

export default connect(undefined, { userLogout: userLogout() })(LogoutButton);
