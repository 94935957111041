import React from 'react';
import {
  BooleanField,
  List,
  Datagrid,
  TextField,
  // ReferenceField,
  DateField,
} from 'react-admin';
import ElapsedActiveTimeField from './ElapsedActiveTimeField';

const simpleDateOptions = {
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

const OrderTypeCombineField = ({ record }) => {
  const { name } = record.customerInfo;
  if (/delivery/i.test(name)) return <span>{record.orderType}</span>;
  return <span>{name}</span>;
};

export default props => (
  <List {...props}>
    <Datagrid>
      <DateField source="createdAt" label="Date" options={{ ...simpleDateOptions, month: 'short' }} />
      <OrderTypeCombineField source="name" label="Name" {...props} />
      <TextField source="customerInfo.phone" label="Phone" />
      <TextField source="orderTotal" label="Order Total" />
      <BooleanField source="isPaid" label="Paid?" />
      <BooleanField source="customerIsAware" label="Customer Is Aware?" />
      <BooleanField source="wasPickedUp" label="Picked Up?" />
      <DateField source="pickedUpAt" label="Date Picked Up" options={simpleDateOptions} />
      <TextField source="removalReason" label="Removed?" />
      <DateField source="removedAt" label="Date Removed" options={simpleDateOptions} />
      <DateField source="updatedAt" label="Last Updated" options={simpleDateOptions} />
      <ElapsedActiveTimeField source="createdAt" label="Time in Box(min)" />
      <TextField source="businessInfo.runnerInfo.name" label="Runner" />
      {/* <TextField source="businessInfo.runnerInfo.name" label="Admin" /> */}
    </Datagrid>
  </List>
);
