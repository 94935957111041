import React from 'react';
import {
  Show,
  SimpleShowLayout,
  Edit,
  ShowButton,
  EditButton,
  TextInput,
  DisabledInput,
  SimpleForm,
  List,
  Datagrid,
  TextField,
} from 'react-admin';

export const CustomerList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="phone" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const CustomerEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <DisabledInput source="phone" />
    </SimpleForm>
  </Edit>
);

export const CustomerShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="phone" />
    </SimpleShowLayout>
  </Show>
);
