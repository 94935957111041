import React from 'react';
import PropTypes from 'prop-types';

const convertToMin = ms => Math.round(ms / 60000);

const ElapsedActiveTimeField = ({ source, record = {} }) => {
  const {
    wasRemoved,
    wasPickedUp,
    pickedUpAt,
    removedAt,
  } = record;
  const startTime = Date.parse(record[source]);
  const elapsedTime = convertToMin(new Date().getTime() - startTime);
  const style = elapsedTime > 25 ? { color: 'red' } : { color: 'green' };

  return wasPickedUp || wasRemoved
    ? <span>{convertToMin(Date.parse(pickedUpAt || removedAt) - startTime)}</span>
    : <span style={style}>{elapsedTime}</span>;
};

ElapsedActiveTimeField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object,
};

ElapsedActiveTimeField.defaultProps = {
  record: {},
};

export default ElapsedActiveTimeField;
