import React, { Component } from 'react';
import buildGraphqlProvider from 'ra-data-graphql-simple';
import { Admin, Resource } from 'react-admin';
import Login from './Login';
import Logout from './LogoutButton';
import { auth, authProvider } from '../Auth';
import history from '../history';
import gqlProviderOptions from '../Apollo';

import {
  BusinessShow,
  BusinessList,
  BusinessEdit,
} from './Business';
import {
  RunnerList,
  RunnerShow,
  RunnerEdit,
  RunnerCreate,
} from './Runner';
import OrderList from './Order';
import { CustomerList, CustomerShow } from './Customer';

class App extends Component {
  constructor() {
    super();
    this.state = {
      authCheckComplete: false,
    };
  }

  componentDidMount = async () => {
    if (/access_token|id_token|error/.test(window.location.hash)) {
      await auth.handleAuthentication();
      buildGraphqlProvider(gqlProviderOptions).then(dataProvider => this.setState({
        dataProvider,
        authCheckComplete: true,
      }));
      return;
    }
    try {
      await auth.renewSession();
      await buildGraphqlProvider(gqlProviderOptions).then(dataProvider => (
        this.setState({
          dataProvider,
          authCheckComplete: true,
        })
      ));
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
      auth.login();
    }
  }

  render() {
    const { dataProvider, authCheckComplete } = this.state;
    if (!dataProvider || !authCheckComplete) {
      return <div />;
    }
    return (
      <Admin title="The Yummy Admin" loginPage={Login} dataProvider={dataProvider} authProvider={authProvider} history={history} logoutButton={Logout}>
        <Resource
          name="YummyBusiness"
          options={{ label: 'Business' }}
          list={BusinessList}
          edit={BusinessEdit}
          show={BusinessShow}
        />
        <Resource
          name="YummyRunner"
          options={{ label: 'Runners' }}
          list={RunnerList}
          create={RunnerCreate}
          edit={RunnerEdit}
          show={RunnerShow}
        />
        <Resource name="Order" list={OrderList} />
        <Resource
          name="Customer"
          list={CustomerList}
          show={CustomerShow}
        />
      </Admin>
    );
  }
}

export default App;
