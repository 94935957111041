import { buildQuery } from 'ra-data-graphql-simple';
import gql from 'graphql-tag';

export default introspection => (fetchType, resource, params) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);
  console.log(fetchType);
  if (resource === 'Order' && fetchType === 'GET_LIST') {
    return {
      ...builtQuery,
      query: gql`
      query allOrders($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: OrderFilter) {
        items: allOrders(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {
          id
          businessInfo {
            name
            location
            mainContact
            phone
            email
            runnerInfo {
              id
              name
            __typename
            }
            __typename
          }
          customerInfo {
            id
            name
            phone
            email
            __typename
          }
          orderTotal
          orderType
          isPaid
          customerIsAware
          wasPickedUp
          wasRemoved
          removalReason
          pickedUpAt
          removedAt
          createdAt
          updatedAt
          __typename
        }
        total: _allOrdersMeta(page: $page, perPage: $perPage, filter: $filter) {
          count
          __typename
        }
      }
      `,
    };
  }
  if (resource === 'YummyRunner' && fetchType === 'CREATE') {
    const { name = '', pin } = params.data;
    if (!name.length) throw new Error('Must have a name!');
    if (pin.length < 4 || pin.length > 6) throw new Error('Pin must be 4-6 characters!');
    if (pin.replace(/[^\d]/g, '').length < 4 || pin.replace(/[^\d]/g, '').length > 6) throw new Error('Pin must be numbers only!');
    const variables = { input: { name, pin } };
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables,
      // Override the query
      query: gql`
        mutation createYummyRunner($input: CreateRunner!){
          data: createYummyRunner(input: $input) {
            id
            name
            pin          
          }
        }`,
    };
  }
  if (resource === 'YummyRunner' && fetchType === 'UPDATE') {
    const { id, data: { name = '', pin } } = params;
    if (!name.length) throw new Error('Must have a name!');
    if (pin.length < 4 || pin.length > 6) throw new Error('Pin must be 4-6 characters!');
    if (pin.replace(/[^\d]/g, '').length < 4 || pin.replace(/[^\d]/g, '').length > 6) throw new Error('Pin must be numbers only!');
    const variables = { input: { id, name, pin } };
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables,
      // Override the query
      query: gql`
    mutation updateYummyRunner($input: UpdateRunner!){
      data: updateYummyRunner(input: $input) {
        id
        name
        pin          
      }
    }`,
    };
  }
  if (resource === 'YummyRunner' && fetchType === 'DELETE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables: {
        id: params.id,
      },
      // Override the query
      query: gql`
      mutation deleteRunner($id: ID!){
        data: deleteRunner(id: $id, businessType: YUMMY) {
          id
          name
          pin
        }
      }`,
    };
  }
  if (resource === 'YummyBusiness' && fetchType === 'GET_LIST') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
      query {
        items: allYummyBusinesses {
          id
          name
          location
          mainContact
          masterCode
          phone
          email
          createdAt
          updatedAt
        }
        total: _allYummyBusinessesMeta {
          count
        }
      }
      `,
    };
  }
  if (resource === 'YummyBusiness' && fetchType === 'GET_ONE') {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      // Override the query
      query: gql`
      query {
        data: YummyBusiness {
          id
          name
          location
          mainContact
          masterCode
          phone
          email
          createdAt
          updatedAt
        }
      }
      `,
    };
  }
  if (resource === 'Order' && fetchType === 'UPDATE') {
    const {
      id, orderTotal, isPaid, confirmedPickup,
    } = params.data;
    const input = { orderTotal, isPaid, confirmedPickup };
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables: {
        id,
        input,
      },
      // Override the query
      query: gql`
      mutation updateOrder($id: ID!, $input: UpdateOrder!) {
        data: updateOrder(id: $id, input: $input) {
          id
          businessInfo {
            id
            name
            location
            mainContact
            phone
            email
            runnerInfo {
              id
              name
            }
            __typename
          }
          customerInfo {
            id
            name
            phone
            __typename
          }
          orderTotal
          orderType
          isPaid
          customerIsAware
          wasPickedUp
          wasRemoved
          createdAt
          updatedAt
          pickedUpAt
          removedAt
          __typename
        }
      }
      `,
    };
  }
  if (resource === 'Customer' && fetchType === 'UPDATE') {
    const { id, name } = params.data;
    const input = { name };
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables: {
        id,
        input,
      },
      // Override the query
      query: gql`
      mutation updateCustomer($id: ID!, $input: UpdateCustomer!) {
        data: updateCustomer(id: $id, input: $input) {
          id
          name
          location
          mainContact
          phone
          email
          createdAt
          updatedAt
          __typename
        }
      }
      `,
    };
  }
  if (resource === 'HappyBusiness' && fetchType === 'UPDATE') {
    const { id, mainContact, lockerConfig } = params.data;
    const input = { mainContact, lockerConfig };
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      variables: {
        id,
        input,
      },
      // Override the query
      query: gql`
      mutation updateHappyBusiness($id: ID!, $input: UpdateBusiness!) {
        data: updateHappyBusiness(id: $id, input: $input) {
          id
          name
          location
          mainContact
          phone
          email
          createdAt
          updatedAt
          __typename
        }
      }
      `,
    };
  }
  return builtQuery;
};
