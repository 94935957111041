import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR,
} from 'react-admin';
import auth from './Auth';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    console.log('yo');
    auth.logout();
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return auth.isAuthenticated() ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  return Promise.resolve();
};
