import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userLogin } from 'react-admin';
import { auth } from '../Auth';

const handleAuthentication = location => (
  /access_token|id_token|error/.test(location.hash)
    ? auth.handleAuthentication()
    : auth.login()
);

class Login extends Component {
  componentDidMount = () => {
    const { userLogin: userLoginFnc, location } = this.props;
    return auth.isAuthenticated()
      ? userLoginFnc()
      : handleAuthentication(location);
  }

  render() {
    return <div>You are not logged in</div>;
  }
}

Login.propTypes = {
  userLogin: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(undefined, { userLogin })(Login);
