
import auth0 from 'auth0-js';
import history from '../history';

export class Auth {
  accessToken;

  idToken;

  expiresAt;

  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACKURL,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.getExpiryDate = this.getExpiryDate.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) reject(err);
        this.setSession(authResult);
        history.replace('/');
        resolve();
        // } else if (err) {
        //   if (process.env.REACT_APP_APP_ENV === 'local') {
        //     alert(`Error: ${err.error}. Check the console for further details.`);
        //   }
      });
    });
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('isLoggedIn', true);
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
    this.scheduleRenewal();
    // navigate to the home route
    history.replace('/');
  }

  scheduleRenewal() {
    const { expiresAt } = this;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, 30000);
    }
  }

  renewSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) reject(err);
        this.setSession(authResult);
        resolve();
        // } else if (err) {
        //   this.logout();
        //   if (process.env.REACT_APP_APP_ENV === 'local') {
        //     alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
        //   }
      });
    });
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt));
  }

  logout() {
    this.auth0.logout({
      returnTo: `${process.env.REACT_APP_DOMAIN}/login`,
      clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    });
  }

  isAuthenticated() {
    // Check whether the current time is past the Access Token's expiry time
    const { expiresAt } = this;
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }
}

export default new Auth();
